import * as THREE from 'three'
import $ from 'jquery'
import toolkit from './../utils/toolkit'

export default function sceneSetup(canvas){
    const self = this
    
    self.setAspect = function(){
        self.camera.aspect = self.canvas.width() / self.canvas.height()
        self.camera.updateProjectionMatrix()

        self.renderer.setSize( self.canvas.width(), self.canvas.height() )
    }
    self.animateStars = function() {
		// loop through each star
		for(var i=0; i<self.stars.length; i++) {
			let star = self.stars[i];
			// and move it forward dependent on the mouseY position.
			star.position.z +=  i/10;
			// if the particle is too close move it to the back
			if(star.position.z>2000) star.position.z-=4000;
		}

	}
        ////////////////////////////            \\\\\\\\\\\\\\\\\\\\\\\\\\\\
    //\\\\\\\\\\\\\----------------  = RENDER =  ----------------///////////////
        //\\\\\\\\\\\\\\\\\\\\\\\\\\            ////////////////////////////
    self.render = function(){
        // Render
        self.renderer.render(self.scene, self.camera)
        self.camera.rotation.z += .01
        self.animateStars()
    }
    self.init = function(){
        //----------------------------------------------------------------------
        // Scene/Camera Setup
        self.canvas = $(canvas)
        self.scene = new THREE.Scene()
        self.camera = new THREE.PerspectiveCamera(90, self.canvas.width()/self.canvas.height(), .1, 1000)
        self.camera.position.z = 5
        
        //----------------------------------------------------------------------
        // Mesh Setup

        self.stars = []
		// The loop will move from z position of -1000 to z position 1000, adding a random particle at each position.
		for ( let z= -1000; z < 1000; z+=30 ) {

			// Make a sphere (exactly the same as before).
			let geometry   = new THREE.SphereGeometry(toolkit.rand(1.5, .5), 8, 8)
			let material = new THREE.MeshBasicMaterial( {color: 0xffffff} );
			let sphere = new THREE.Mesh(geometry, material)

			// This time we give the sphere random x and y positions between -500 and 500
			sphere.position.x = Math.random() * 1000 - 500;
			sphere.position.y = Math.random() * 1000 - 500;

			// Then set the z position to where it is in the loop (distance of camera)
			sphere.position.z = z;

			// scale it up a bit
			sphere.scale.x = sphere.scale.y = 2;

			//add the sphere to the scene
			self.scene.add( sphere );

			//finally push it to the stars array
			self.stars.push(sphere);
		}

        //----------------------------------------------------------------------
        // Lighting Setup
        self.lights = {}
        self.lights.ambient = new THREE.AmbientLight(0x000000, .38)
        self.scene.add(self.lights.ambient)

        self.lights.point = new THREE.PointLight(0xffffff, 2, 50, 2)
        self.lights.point.position.set(0, 15, 0)
        self.lights.point.castShadow = true
        self.lights.point.shadow.camera.near = .1; self.lights.point.shadow.camera.far = 25
        self.scene.add(self.lights.point)

        //----------------------------------------------------------------------
        // Renderer Setup
        self.renderer = new THREE.WebGLRenderer({ alpha: true })
        self.renderer.setClearColor( 0xffffff, 0);

        self.canvas.append(self.renderer.domElement)
        self.setAspect()
        window.addEventListener( 'resize', ()=>{
            self.setAspect()
        }, false )
    }()
}
