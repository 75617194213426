import $ from 'jquery'
import sceneSetup from './sceneSetup'
import toolkit from './../utils/toolkit'

function main(){
    const self = this
    self.scene = new sceneSetup('.starField')

    self.rollIntro = function(){
        self.introSayings = [
            {first: "Welcome,", second: "fellow traverser."},
            {first: "Asaaaaaaaaaaaaa...", second: "duuuuuuuuuuuuuu!"},
            {first: "What's good", second: "homie!"},
            {first: "Let's get", second: "schwifty"}
        ]

        self.theOne = self.introSayings[toolkit.randInt(self.introSayings.length - 1)]
        $('#first').html(self.theOne.first)
        $('#second').html(self.theOne.second)

        self.sect.welcome.removeClass('hidden')
        self.sect.welcome.addClass('intro')
        setTimeout(function(){
            self.sect.welcome.addClass('hidden')
            self.sect.main.removeClass('hidden')
            setTimeout(function(){
                self.sect.welcome.remove()
            },500)
        },3250)
    }

    self.animate = ()=>{
        self.scene.render()
        requestAnimationFrame(self.animate)
    }

    self.init = function(){
        setTimeout(function(){
            self.sect = {
                welcome: $('section.welcome'),
                main: $('main'),
                aboveFold: $('section.aboveFold'),
                links: $('.links')
            }
            self.rollIntro()
            self.animate()
            //buildForm(paymentForm)
        },500)
    }()
}

if (
    document.readyState === "complete" ||
    (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
    main();
} else {
    document.addEventListener("DOMContentLoaded", main);
} 
    
